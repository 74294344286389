import React from "react";
import "./enrolmentContent.css";
import PageTitle from "../master/PageTittle";
import ContentWrapper from "../master/ContentWrapper";
import SideBar from "../master/SideBar";
import {educational} from "../const";
import YoutubeEmbed from "../../templates/youtubeembed";

const PrimarySchoolContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="Primary School"/>

                <p>
                    The Primary years are essential to building a strong foundation in
                    learning. Small class sizes allow for more individualised learning and
                    greater personalised time between teacher and student.
                </p>

                <p>
                    Students are challenged in lessons and are constantly encouraged and
                    engaged. Students who are high achievers are accelerated and other
                    students who need extra assistance have learning support staff to
                    assist them.
                </p>

                <p>
                    Students learn Arabic or French from kindergarten, they are taught
                    music with a specialised music teacher and across all key learning
                    areas including Religion, Science, Technology Engineering and
                    Mathematics.
                </p>

                <p>
                    Technology supports the learning process via computers, ipads and
                    interactive white boards in each room.
                </p>

                <p>
                    Extra-curricular activities include choir, musicals, individualised
                    piano lessons, Gala days, SRC, chess club, homework club, and
                    lunchtime library activities.
                </p>

                <p>
                    As a small school, it has a very friendly feel and students all know
                    each other and care for one another.
                </p>

                <p>
                    We invite you to visit our Primary School to see our grounds and meet
                    our teachers.
                </p>

                <PageTitle title="What our students Say"/>
                <YoutubeEmbed embedId="Q6les-xOUu4"/>
            </div>
            <SideBar items={educational} title="Educational Excellence"/>
        </ContentWrapper>
    </div>
);

export default PrimarySchoolContent;
