import * as React from "react";
import Layout from "../../components/layout";
import PrimarySchoolContent from "../../components/education/primarySchoolContent";
import heroImage from "../../assets/education/primary-year-hero-image.jpg";
import BannerImage from "../../components/master/BannerImage";
import ContentWrapper from "../../components/master/ContentWrapper";

const PrimaryYearPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="Our Vision, Mission" />
      <PrimarySchoolContent />
    </Layout>
  );
};

export default PrimaryYearPage;
